import React from "react";

export default function RegisterActivate() {
    return <div className="success_message">
        <h2>BENVENUTO SU EQUILIBRA</h2>
        <p>Siamo felici di accoglierti nella nostra community e non vediamo l’ora di soddisfare ogni tua richiesta.<br />
            Cosa aspetti? Esplora il nostro sito per scoprire tutto ciò che abbiamo da offrire!</p>
    </div>;
}


