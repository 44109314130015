import {useEffect, useRef, useState} from "react";
import Success from "./Success";
import {dobSlash, ErrorMap, FormValidate, Provinces, UserFields, Gender} from "../../helper";
import PrivacyNote from "./PrivacyNote";
import ReCAPTCHA from "react-google-recaptcha";
import InputText from "../InputText";
import Select from "../Select";


export default function Register() {

    const recaptchaRef = useRef();

    const [form, setForm] = useState(UserFields);
    const [canSubmit, setCanSubmit] = useState(false);
    const [formError, setFormError] = useState('');
    const [sent, setSent] = useState(false);

    const [regex] = useState({
        set1: false,
        set2: false,
        set3: false,
    });

    useEffect(() => {
        check();
    })

    const checkValid = (el) => {

        const {name, value} = el.target;
        return FormValidate(name, value, form);

    }

    const change = (e) => {

        const {name, value, type, checked} = e.target;

        let _value;
        switch (type) {
            case 'checkbox':
                _value = checked;
                break;
            case 'radio':
                _value = checked ? value : '';
                break;
            default:
                _value = value;
                break;
        }

        let _new = {
            ...form[name], ...{
                value: _value,
                valid: checkValid(e, form),
                touched: true
            }
        }

        setForm(prevForm => {
            return {
                ...prevForm,
                [name]: _new
            }
        });

    }

    const check = () => {

        let invalid = Object.keys(form).filter((item) => {
            if (!form[item].valid) {
                if (form[item].required) {
                    return item
                }
            }
            return false;
        });

        setCanSubmit(invalid.length === 0 && recaptchaRef.current && recaptchaRef.current.getValue() && recaptchaRef.current.getValue().length > 0)

        setFormError('');
    }

    const getDob = () => {
        let int_v = (form.dob.value).split('/');
        return int_v[2] + '-' + int_v[1] + '-' + int_v[0]
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!canSubmit) {
            return;
        }
        setCanSubmit(false);

        let registrationData = {};
        Object.keys(form).forEach(item => {
            registrationData[item] = form[item].value
        });

        delete registrationData.confirm_password;
        registrationData.recaptcha = recaptchaRef.current.getValue();
        registrationData.redirect = window.location.origin + process.env.REACT_APP_BASE_PATH + 'registration-activate';
        registrationData.dob = getDob();

        window['UIdentity'].register(registrationData, (data) => {

            if (data.success) {
                setSent(true);
            } else {
                setCanSubmit(true);
                setFormError(ErrorMap(e.error.message));
                recaptchaRef.current.reset();
            }

        });


    }


    return (<div className="container register">
        {sent ? <Success/> : <div className="form">
            <h1 className="form--title">REGISTRATI</h1>
            <form onSubmit={onSubmit}>

                <PrivacyNote/>

                <h3>INSERISCI I TUOI DATI</h3>
                <div className="form--note">
                    * Tutti i campi contrassegnati dall'asterisco sono obbligatori
                </div>

                <div className="form-items">

                    <InputText type="text" name="first_name" label="Nome*" onChange={change} data={form.first_name}/>

                    <InputText type="text" name="last_name" label="Cognome*" onChange={change} data={form.last_name}/>


                    <Select noEmpty={true} label="Sesso *" options={Gender} onChange={change} value={form.gender.value}
                            name="gender" data={form.gender}/>

                    <div className="form-item">
                        <label>Data di nascita*</label>
                        <input onKeyUp={dobSlash} placeholder="GG/MM/YYYY" onChange={change} value={form.dob.value}
                               className={form.dob.touched ? (form.dob.valid ? 'valid' : 'error') : ''} type="text"
                               name="dob"/>
                    </div>


                    <Select label="Provincia" options={Provinces} onChange={change} value={form.province.value}
                            name="province" data={form.province}/>

                    <InputText maxLength="5" minLength="5" type="text" name="zip" label="CAP" onChange={change}
                               data={form.zip}/>

                    <InputText className="full" type="email" name="email" label="Email*" onChange={change}
                               data={form.email}/>

                    <InputText type="password" name="password" label="Password*" onChange={change}
                               data={form.password}/>

                    <InputText type="password" name="confirm_password" label="Conferma password*" onChange={change}
                               data={form.confirm_password}/>


                </div>

                <ul className="password_pattern">
                    <li className={regex.set1 ? 'ok' : ''}>Deve includere da 8 a 20 fra caratteri e simboli</li>
                    <li className={regex.set2 ? 'ok' : ''}>Deve includere caratteri numerici e lettere</li>
                    <li className={regex.set3 ? 'ok' : ''}>Deve includere almeno 1 lettera maiuscola e 1 minuscola</li>
                </ul>

                <div className="privacy-text">
                    <strong>AVVERTENZE</strong><br /><br />
                    1) I dati contrassegnati con l'asterisco sono necessari per la registrazione.<br />
                    2) Dopo la registrazione potrà aggiornare in qualsiasi momento i Suoi dati tramite apposita sezione dell’area riservata.<br />
                    3) La partecipazione è consentita solo a soggetti interessati maggiorenni.<br /><br />



                    <strong>CONSENSI</strong><br /><br />
                    Presa visione dell’informativa sopra fornita da Equilibra S.r.l. e da Unilever Italia Mkt Operations S.r.l., in qualità di Contitolari del trattamento, acconsente al trattamento dei Suoi dati per la seguente finalità:
                </div>

                <div className="input-container">
                    <div className="checkbox">
                        <input id="optin_marketing" type="checkbox" checked={form.optin_marketing.value}
                               onChange={change} name="optin_marketing"/>
                        <label htmlFor="optin_marketing">Ricevere a mezzo e-mail comunicazioni commerciali e la newsletter periodica a contenuto informativo e promozionale riguardante le iniziative promosse sia Equilibra da che da Unilever con riferimento ai propri prodotti e marchi, nonché a domicilio comunicazioni commerciali, materiale pubblicitario, campioni gratuiti, omaggi, questionari aventi ad oggetto ricerche di mercato, con riferimento ai propri prodotti e marchi
                        </label>
                    </div>
                </div>

                <div className="input-container last">
                    <div className="checkbox">
                        <input type="checkbox" id="optin_profiling" checked={form.optin_profiling.value}
                               onChange={change} name="optin_profiling"/>
                        <label htmlFor="optin_profiling">Ricevere comunicazioni personalizzate sulla base delle proprie preferenze di consumo manifestate attraverso la partecipazione ai concorsi di Unilever e di Equilibra e dei dati anagrafici forniti in sede di registrazione al presente sito web o al sito www.dettofranoi.it</label>
                    </div>
                </div>

                <div className="privacy-text">
                    Ulteriori informazioni sul trattamento dei dati personali sono consultabili cliccando qui:
                    <a rel="noreferrer" href="https://www.equilibra.it/privacy-notice.html" target="_blank"
                       title="Privacy Policy">Privacy Policy</a>
                </div>

                <ReCAPTCHA ref={recaptchaRef} onChange={check} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}/>

                {formError ? <div id="errors" className="form--error">{formError}</div> : ''}

                <div className="form-actions">
                    <button id="submit" type="submit" name="submit" className="button" disabled={!canSubmit}>
                        Registrati
                    </button>
                </div>
            </form>
        </div>}
    </div>);
}