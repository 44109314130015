import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Alert from "../Alert";

export default function Account() {
    const navigate = useNavigate();
    const [showConfirm, setShowConfirm] = useState(false);

    const logout = () => {
        window['UIdentity'].logout('https://www.equilibra.it/');
    }
    
    const deleteAccount = () => {
        window['UIdentity'].delete(function (data) {
            if (data.success){
                navigate('/')
            }
        });
    }
    const hideConfirm = () => {
        setShowConfirm(false);
    }

    return <div className="account">
        <div className="container">

            <div className="account--inner">

                <div className="account--title">Area personale</div>

                <Link to="/modifica-profilo" className="account--link">Modifica Profilo</Link>
                <Link to="/cambia-password" className="account--link">Cambia Password</Link>
                <button className="account--link" onClick={() => { setShowConfirm(true); }}>Elimina Account</button>

                <div className="account--actions">
                    <button onClick={logout} className="button">Disconnetti</button>
                </div>
            </div>

        </div>
        <Alert key={showConfirm} click={deleteAccount} hide={hideConfirm} show={showConfirm} />
    </div>;
}