import React from "react";

export default function Success() {

    return <div className="success_message">
        <h2>Ci siamo quasi</h2>
        <p>Ciao, grazie per esserti iscritto a Equilibra! A breve riceverai una mail per confermare la registrazione e attivare il tuo nuovo account.<br /><br />

            L’email non arriva? Controlla nella posta indesiderata e se non dovessi trovarla, non esitare a contattarci.
        </p>
    </div>;
}