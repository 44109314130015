import './App.scss';
import Register from "./components/Register/Register";
import EditProfile from "./components/EditProfile/EditProfile";
import Account from "./components/Account/Account";
import {
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import React from "react";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import RegisterActivate from "./components/RegisterActivate/RegisterActivate";
import Home from "./components/Home/Home";

const ProtectedRoute = ({ children }) => {
    if (!window['UIdentity'].isLoggedIn()) {
        return <Navigate to="/login" replace />;
    }
    return children;
};

const LogRegRoute = ({ children }) => {
    if (window['UIdentity'].isLoggedIn()) {
        return <Navigate to="/profilo" replace />;
    }
    return children;
};

const Login = () => {
    if (!window['UIdentity'].isLoggedIn()) {
        window.location.href =  window['UIdentity'].getLoginUrl();
    }
}
const Logout = () => {
    window['UIdentity'].logout('https://www.equilibra.it/');
}
const AccountRedirect = () => {

    if (window['UIdentity'].isLoggedIn()) {
        return <Navigate to="/profilo" replace />;
    }else{
        return <Navigate to="/registrazione" replace />;
    }
}

export default function App() {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/logout" element={<Logout />} />
            <Route path="/account" element={<AccountRedirect />} />
            <Route path="/it-IT/account" element={<AccountRedirect />} />
            <Route exact path="/login" element={<LogRegRoute><Login /></LogRegRoute>} />
            <Route path="/registrazione" element={<LogRegRoute><Register /></LogRegRoute>} />
            <Route path="/registration-activate" element={<RegisterActivate />} />

            <Route path="/profilo" element={<ProtectedRoute><Account /></ProtectedRoute>} />
            <Route path="/cambia-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />
            <Route path="/modifica-profilo" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
        </Routes>
    );
}

