import React, { useState} from "react";

export default function Alert({show, click, hide}) {
    const [isShow] = useState(show);

    return <div className={`${isShow ? 'show' : 'hide'} alert`}>
        <div className="alert--content">
            <div className="alert--text">
                Procedendo, eliminerai tutte le informazioni e la cronologia utente in modo definitivo. Vuoi continuare?
            </div>
            <div className="alert--buttons">
                <button onClick={click}>Elimina Account</button>
                <button onClick={hide}>Annulla</button>
            </div>
        </div>
    </div>
}