import React from "react";

export default function PrivacyNote() {

    return <div className="privacy-box">
        <div className="privacy-text"><strong>Informativa privacy ai sensi dell’art. 13 del Reg. UE 679/2016</strong>
            <br/><br/>
            Gentile Utente,<br/>

            Equilibra S.r.l. (di seguito “Equilibra”, con sede in via Plava n. 74, Torino) è una società del Gruppo Unilever, consociata di Unilever Italia Mkt. Operations S.r.l. (di seguito “Unilever”, con sede legale in via Paolo di Dono n. 3/A). Equilibra e Unilever hanno deciso di consentire agli utenti dei loro siti web <a href="https://www.equilibra.it" target="_blank">www.equilibra.it</a> e <a href="https://www.dettofranoi.it" target="_blank">www.dettofranoi.it</a> di accedere con la medesima utenza, in modo da facilitare la navigazione degli utenti su tali siti.
            <br /><br/>
            Pertanto, per le finalità di seguito riportate, le società opereranno in qualità di Contitolari del trattamento sulla base di uno specifico accordo il cui estratto è consultabile <a href="https://www.equilibra.it/accordo-di-contitolarita.html" target="_blank">cliccando qui</a>.
            <br /><br/>
            Equilibra e Unilever, in qualità di Contitolari del trattamento dei dati personali degli utenti che si iscriveranno a questo sito, informano che i dati personali richiesti nel Form di registrazione servono a:
            <br /><br/>
            1)  Creare un’utenza personale sul presente sito web con cui poter, di volta in volta, iscriversi e partecipare ai concorsi di Equilibra e di Unilever che saranno pubblicati sul sito <a href="https://www.dettofranoi.it" target="_blank">www.dettofranoi.it</a> e, quanto ai concorsi di Equilibra, accessibili anche sul sito <a href="https://www.equilibra.it" target="_blank">www.equilibra.it</a>, e che potrà essere utilizzata per accedere anche all’area riservata del medesimo sito <a href="https://www.dettofranoi.it" target="_blank">www.dettofranoi.it</a>. Per la disciplina e il funzionamento dei singoli concorsi, si rinvia al Regolamento che, per ciascuna iniziativa, sarà pubblicato sul sito <a href="https://www.dettofranoi.it" target="_blank">www.dettofranoi.it</a> o su un sito dedicato e, quanto ai concorsi di Equilibra, raggiungibile anche dal sito <a href="https://www.equilibra.it" target="_blank">www.equilibra.it</a>.
            <br /><br/>
            2)  Previo consenso, ricevere a mezzo e-mail comunicazioni commerciali e la newsletter periodica a contenuto informativo e promozionale riguardante le iniziative promosse sia da Equilibra che da Unilever con riferimento ai loro prodotti e marchi, e a domicilio comunicazioni commerciali, materiale pubblicitario, campioni gratuiti, omaggi, questionari aventi ad oggetto ricerche di mercato, con riferimenti ai prodotti e ai marchi di Unilever e di Equilibra.
            <br /><br/>
            3) Previo consenso, ricevere comunicazioni personalizzate, anche tramite terze parti, sulla base delle proprie preferenze di consumo manifestate attraverso la partecipazione ai concorsi di Unilever e di Equilibra e dei dati anagrafici forniti in sede di registrazione al presente sito web e al sito <a href="https://www.dettofranoi.it" target="_blank">www.dettofranoi.it</a>.
            <br /><br/>
            Il trattamento dei dati personali per la finalità di cui al precedente punto 1) è necessario, ai sensi dell’art. 6 lett. b) del Reg. UE 679/2016, al fine di permetterLe di registrarsi sul presente sito web e creare un’utenza personale con cui poter, di volta in volta, decidere di iscriversi e partecipare ai concorsi di Unilever e di Equilibra accedendo al sito <a href="https://www.dettofranoi.it" target="_blank">www.dettofranoi.it</a> e/o <a href="https://www.equilibra.it" target="_blank">www.equilibra.it</a>. . Il conferimento dei dati per questa finalità non è obbligatorio; in caso di rifiuto, tuttavia, non sarà possibile completare la procedura di registrazione al sito.
            <br /><br/>
            Il conferimento del consenso per la finalità di cui al precedente punto 2) e 3) è facoltativo; in caso di mancato rilascio, l'unica conseguenza sarà che i dati non saranno trattati per la suddetta finalità e non sarà in ogni caso precluso all’utente la possibilità di utilizzare le altre funzionalità del presente sito web.
            <br /><br/>
            Il consenso fornito potrà in ogni momento essere revocato scrivendo a consumer-service.it@unilever.com oppure accedendo ad apposita sezione all’interno dell’area riservata. L'eventuale revoca del consenso non compromette la liceità del trattamento avvenuto fintanto che il consenso era validamente prestato.
            <br /><br/>
            Per il perseguimento delle finalità di cui sopra, i Contitolari potranno fare ricorso ai servizi di fornitori esterni, che si occupano ad esempio della gestione e manutenzione tecnica del sito. Tali soggetti sono stati nominati a tal fine Responsabili del trattamento, in conformità con l’art. 28 del Regolamento Europeo 679/2016, e tratteranno i Suoi dati personali solo su precisa istruzione da parte di Unilever e di Equilibra.
            <br /><br/>
            Il trattamento dei dati per la finalità di cui al punto 1) verrà effettuato per il tempo necessario al conseguimento della finalità più per l’ulteriore periodo previsto dalla legge. Il trattamento dei dati per la finalità di cui al punto 2) verrà effettuato per un periodo di 5 anni decorrente dall’ultimo utilizzo dell’account. Il trattamento dei dati per la finalità di cui al punto 3) verrà effettuato per un periodo di un anno decorrente dalla data di ultimo utilizzo dell’account utente.
            <br /><br/>

            N.B. Non rientra nell’accordo di contitolarità il trattamento dei dati personali necessario per dare esecuzione al singolo concorso cui Lei dovesse decidere di iscriversi. Unilever ed Equilibra saranno infatti, rispettivamente, ciascuna promotrice esclusivamente dei propri concorsi, come sarà chiaramente indicato nel Regolamento di ogni iniziativa. In tale caso ciascuna società, quale promotrice del proprio concorso, tratterà, in qualità di autonomo Titolare del trattamento, i dati personali da Lei forniti, al fine di gestire la Sua partecipazione all’iniziativa (ad es., per la consegna dell’eventuale premio). In ogni caso, per la disciplina e il funzionamento dei concorsi, si rinvia al Regolamento che, per ciascuna iniziativa, sarà pubblicato sul sito <a href="https://www.dettofranoi.it" target="_blank">www.dettofranoi.it</a> o su un sito dedicato e, per quanto riguarda quelli di Equilibra, raggiungibile anche dal sito <a href="https://www.equilibra.it" target="_blank">www.equilibra.it</a>.
            <br /><br/>

            Unilever ha nominato anche un Data Protection Officer (DPO) contattabile ai seguenti recapiti: mail unilever.privacy@unilever.com; oppure scrivendo a Group Data Protection Officer Unilever N.V. Weena 455, 3013 AL Rotterdam, Paesi Bassi.
            <br /><br/>
            Gli utenti potranno, in ogni momento e gratuitamente, esercitare tutti i diritti di cui agli articoli 15 e seguenti del Regolamento Europeo 679/2016, inclusi l’accesso, la modifica, l'integrazione e/o la cancellazione dei dati, la revoca dei consensi precedentemente prestati, la limitazione del trattamento o la portabilità dei dati personali, scrivendo al seguente indirizzo: unilever.privacy@unilever.com.
            <br /><br/>
            Per i trattamenti che non rientrano nell’accordo di contitolarità, potrà esercitare i predetti diritti rivolgendosi ad Equilibra s.r.l., quale Titolare del Trattamento, scrivendo all’indirizzo privacy@equilibra.it.
            <br /><br/>
            Qualora ravvisasse una violazione dei suoi diritti potrà rivolgersi al Garante per la protezione dei dati personali, competente ai sensi dell'art. 77 del GDPR; resta salva la possibilità di rivolgersi direttamente all'autorità giudiziaria.

        </div>
    </div>
        ;
}