import React from "react";

export default function Select({label, onChange, data, name, className, options, onBlur, noEmpty}) {

    return <div className="form-item">
        <label>{label}</label>
        <select onBlur={onBlur} onChange={onChange} value={data.value} className={`${className} ${(data.touched ? (data.valid ? 'valid' : 'error') : '')}`} name={name}>
            {noEmpty ? '' : <option value="">Seleziona</option> }
            {Object.keys(options).map((item) => {
                return <option key={`${name}_${item}`} value={item}>{options[item]}</option>
            })}
        </select>
    </div>
}