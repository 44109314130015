import {useState, useEffect} from "react";
import {
  dobSlash,
  ErrorMap,
  FormValidate,
  getDob,
  Provinces,
  UserFields,
    Gender
} from "../../helper";
import Select from "../Select";
import InputText from "../InputText";


export default function EditProfileForm({customer}) {

  const [form, setForm] = useState(customer);
  const [canSubmit, setCanSubmit] = useState(true);
  const [formError, setFormError] = useState('');
  const [sent, setSent] = useState(false);


  useEffect(() => {


  }, [])

  const change = (e) => {

    const {name, value, type, checked} = e.target;

    let _value;
    switch (type) {
      case 'checkbox':
        _value = checked;
        break;
      case 'radio':
        _value = checked ? value : '';
        break;
      default:
        _value = value;
        break;
    }

    let _new = form[name];
    _new.value = _value;

    setForm(prevForm => {
      return {
        ...prevForm,
        [name]: _new
      }
    });

  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (!valid()) {
      return;
    }
    setCanSubmit(false);

    let registrationData = {};
    Object.keys(form).forEach(item => {
      if (form[item].touched) {
        registrationData[item] = form[item].value
      }
    });
    registrationData.dob = getDob(form.dob.value);

    if (!registrationData.zip) {
      delete registrationData.zip;
    }

    window['UIdentity'].edit(registrationData, function (data) {
      setCanSubmit(true);
      if (data.success) {
        setSent(true);
      }
      else {
        setFormError(ErrorMap(data.error));
      }
    });
    let consents = [{
        "id": 4,
        "status": form['optin_marketing'].value
      },{
      "id": 6,
      "status": form['optin_profiling'].value
    }
    ];

    window['UIdentity'].setConsents(consents, function (data) {});


  }

  const onBlur = (e) => {
    const {name, value} = e.target;

    let newForm = form[name];
    newForm.valid = FormValidate(name, value, form);

    setForm(prevForm => {
      return {...prevForm, [name]: newForm}
    });
    setFormError('');
  }

  const valid = () => {
    let newForm = form;
    Object.keys(newForm).forEach((item) => {
      newForm[item].valid = FormValidate(item, newForm[item].value, newForm);
      newForm[item].touched = true;
    });

    let invalid = Object.keys(newForm).filter(item => {
      return !newForm[item].valid
    });
    setForm(prevForm => {
      return {...prevForm, ...newForm}
    });

    return invalid.length === 0
  }

  return (<form onSubmit={onSubmit}>

    <div className="form--note">
      * Tutti i campi contrassegnati dall'asterisco sono obbligatori
    </div>

    <div className="form-items">

      <InputText onblur={onBlur} type="text" name="first_name" label="Nome*"
                 onChange={change} data={form.first_name}/>

      <InputText onblur={onBlur} type="text" name="last_name" label="Cognome*"
                 onChange={change} data={form.last_name}/>

      <Select noEmpty={true} label="Sesso *" options={Gender} onChange={change} value={form.gender.value}
              name="gender" data={form.gender}/>

      <div className="form-item">
        <label>Data di nascita*</label>
        <input onBlur={onBlur} onKeyUp={dobSlash} placeholder="GG/MM/YYYY"
               onChange={change} value={form.dob.value}
               className={form.dob.touched ? (form.dob.valid ? 'valid' : 'error') : ''}
               type="text" name="dob"/>
      </div>

      <Select onBlur={onBlur} label="Provincia" options={Provinces}
              onChange={change} value={form.province.value}
              name="province" data={form.province}/>

      <InputText onblur={onBlur} maxLength="5" minLength="5" type="text"
                 name="zip" label="CAP" onChange={change}
                 data={form.zip}/>

    </div>

    <div className="privacy-text">
      <strong>CONSENSI</strong><br /><br />
      Presa visione dell’informativa sopra fornita da Equilibra S.r.l. e da Unilever Italia Mkt Operations S.r.l., in qualità di Contitolari del trattamento, acconsente al trattamento dei Suoi dati per la seguente finalità:
    </div>


    <div className="input-container">
      <div className="checkbox">
        <input id="optin_marketing" type="checkbox"
               checked={form.optin_marketing.value} onChange={change}
               name="optin_marketing"/>
        <label htmlFor="optin_marketing">Ricevere a mezzo e-mail comunicazioni commerciali e la newsletter periodica a contenuto informativo e promozionale riguardante le iniziative promosse sia Equilibra da che da Unilever con riferimento ai propri prodotti e marchi, nonché a domicilio comunicazioni commerciali, materiale pubblicitario, campioni gratuiti, omaggi, questionari aventi ad oggetto ricerche di mercato, con riferimento ai propri prodotti e marchi
        </label>
      </div>
    </div>

    <div className="input-container last">
      <div className="checkbox">
        <input type="checkbox" id="optin_profiling"
               checked={form.optin_profiling.value} onChange={change}
               name="optin_profiling"/>
        <label htmlFor="optin_profiling">
          Ricevere comunicazioni personalizzate sulla base delle proprie preferenze di consumo manifestate attraverso la partecipazione ai concorsi di Unilever e di Equilibra e dei dati anagrafici forniti in sede di registrazione al presente sito web o al sito www.dettofranoi.it
        </label>
      </div>
    </div>

    <div className="privacy-text">
      Ulteriori informazioni sul trattamento dei dati personali sono consultabili cliccando qui:
      <a rel="noreferrer" href="https://www.equilibra.it/privacy-notice.html" target="_blank"
         title="Privacy Policy">Privacy Policy</a>
    </div>

    {formError ?
        <div id="errors" className="form--error">{formError}</div> : ''}
    {sent ? <div className="form--success">il tuo profilo stato salvato
      correttamente</div> : ''}
    <div className="form-actions">
      <button id="submit" type="submit" name="submit" className="button"
              disabled={!canSubmit}>
        Salva
      </button>
    </div>
  </form>);
}