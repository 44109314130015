import React, {Fragment} from "react";
import './home.scss'

export default function Home() {
    return <div className="equilibra">
        <div className="equilibra--logo">
            <div className="equilibra--logo-image">
                <img src="/images/logo-equilibra.png" alt="Logo Equilibra" />
            </div>
            <div className="equilibra--logo-text">
                Gentili Utenti stiamo rifacendo il nostro Store ONLINE,  quindi non è al momento possibile acquistare i prodotti Equilibra sul nostro sito.
                <br className="nomob" /> Ci scusiamo per il disagio.
            </div>
            <div className="equilibra--logo-title">
                Dove acquistare
            </div>
        </div>
        <div className="container">
            <div className="equilibra--card-wrapper">
                <a className="equilibra--card store" href="https://www.equilibra.it/contatti.html" target="_blank" rel="nofollow">
                    <div className="equilibra--card-image">
                        <img src="/images/pc_shop.jpg" alt="" />
                    </div>
                    <div className="equilibra--card-text">
                        Nei migliori canali ONLINE (ad es. Amazon)
                    </div>
                    <div className="equilibra--card-btn">
                        <span>Contattaci adesso</span>
                    </div>
                </a>
                <a className="equilibra--card store" rel="nofollow" target="_blank" href="https://www.equilibra.it/contatti.html">
                    <div className="equilibra--card-image">
                        <img src="/images/farmacie.jpg" alt="Logo Store" />
                    </div>
                    <div className="equilibra--card-text">
                        Nelle migliori<br className="nomob" />
                        Farmacie ONLINE
                    </div>
                    <div className="equilibra--card-btn">
                        <span>Contattaci adesso</span>
                    </div>
                </a>
                <a className="equilibra--card market" rel="nofollow" target="_blank" href="https://www.equilibra.it/contatti.html">
                    <div className="equilibra--card-image">
                        <img src="/images/supermarket.jpg" alt="Logo Market" />
                    </div>
                    <div className="equilibra--card-text">
                        Nei migliori Supermercati,<br className="nomob" />
                        Ipermercati, Drugstore e Discount
                    </div>
                    <div className="equilibra--card-btn">
                        <span>Contattaci adesso</span>
                    </div>
                </a>
                <a className="equilibra--card store-equilibra" target="_blank" rel="nofollow" href="https://www.equilibra.it/chi-siamo/stores.html">
                    <div className="equilibra--card-image">
                        <img src="/images/stores.jpg" alt="Logo store Equilibra" />
                    </div>
                    <div className="equilibra--card-text">
                        Visita i negozi Equilibra<br className="nomob" />
                        di Torino, Milano e Roma
                    </div>
                    <div className="equilibra--card-btn">
                        <span>Scopri di più</span>
                    </div>
                </a>
            </div>
        </div>
    </div>;
}