import {useEffect, useState} from "react";
import {getDobR, UserFields} from "../../helper";
import {useNavigate} from "react-router-dom";
import Loader from "../Loader";
import EditProfileForm from "./EditProfileForm";

export default function EditProfile() {

  const [customer, setCustomer] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {

    window['UIdentity'].get(function (data) {
      if (data.success) {
        let valid_fields = ['first_name', 'last_name', 'gender', 'dob', 'province', 'zip', 'optin_marketing', 'optin_profiling'];
        let customer = data.customer;
        let f = {};
        Object.keys(UserFields).forEach((item) => {
          if (valid_fields.includes(item)) {
            f[item] = UserFields[item];

            if (item === 'dob') {
              f[item].value = getDobR(customer[item]);
            }
            else {
              f[item].value = customer[item];
            }

          }
        });

        if (customer.consents) {
          customer.consents.forEach(item => {
            if (item.consent_id === 4) {
              f['optin_marketing'].value = item.status
            }
            if (item.consent_id === 6) {
              f['optin_profiling'].value = item.status
            }

          });
        }


        setCustomer(f)
      }
      else {
        console.error(data);
      }
    });


  }, []);


  const back = () => {
    navigate('/profilo')
  }

  return (<div className="container register">
    <div className="form">
      <button className="button plain" onClick={back}> &lt; Indietro</button>
      <h1 className="form--title">Modifica Profilo</h1>
      {customer ? <EditProfileForm customer={customer}/> : <Loader/>}
    </div>
  </div>);
}