export default function Footer() {

    return <footer>
        <div className="container-content green">
            Equilibra S.r.l., con sede in via Plava 74, Torino (TO) - P.I. 06570290012 - Iscrizione Registro Imprese di
            Torino n. 798347 - Cap. Soc. € 10.400,00 &#61; interamente versato
        </div>
        <div className="container-content phone">
            <img src="https://s3.eu-central-1.amazonaws.com/unilever.upscale/images/equilibra/numero-verde.png"/>
        </div>

        <div className="container-content">
            <div className="menus">
                <a href="https://www.equilibra.it/privacy-notice.html" target="_blank" rel="noopener noreferrer">Privacy
                    Notice</a>
                <a href="https://www.equilibra.it/cookie-policy.html" target="_blank" rel="noopener noreferrer">Cookie
                    Policy</a>
                <a href="https://www.equilibra.it/sitemap.html" target="_blank" rel="noopener noreferrer">Sitemap</a>
            </div>

            <div className="secondary-nav">
                <div className="logo">
                    <a href="https://www.equilibra.it"><img
                        src="https://s3.eu-central-1.amazonaws.com/unilever.upscale/images/equilibra/equilibra.png"/></a>
                </div>
            </div>

        </div>
    </footer>
}