import {useState} from "react";

export default function Header() {
    const [loggedIn] = useState(window['UIdentity'].isLoggedIn());
    const [menuOpened, setMenuOpened] = useState(false);

    const openMenu = (e) => {
        setMenuOpened(!menuOpened);
    }

    return <header className="header">
        <div className="header container-content">
            <div className="header__top">
                <div className="header__top-left">
                    <div className="header__top-right"></div>
                    <div className="header__logo-image-wrapper">
                        <a href="https://www.equilibra.it">
                            <img className="header__logo-image desktop" src="https://s3.eu-central-1.amazonaws.com/unilever.upscale/images/equilibra/equilibra.png" />
                        </a>
                    </div>
                </div>
                <button className="burger icon-hamburger-menu" onClick={openMenu}></button>
                <div className={`header__top-navbar ${menuOpened ? 'has-menu' : ''}`}>
                    <nav>
                        <button className="close icon-close" onClick={openMenu}></button>
                        <ul>
                            <li><a href="https://www.equilibra.it"><span
                                 className="label">Home</span></a></li>
                            
                            <li ><a 
                                                               href="https://www.equilibra.it/chi-siamo/azienda.html"><span
                                 className="label">Chi siamo</span></a>
                            </li>
                            
                            <li ><a 
                                                               href="https://www.equilibra.it/prodotti/integratori-alimentari/alimenti.html"><span
                                 className="label">Integratori alimentari</span></a>
                            </li>
                            
                            <li ><a 
                                                               href="https://www.equilibra.it/prodotti/cosmetica/capelli.html"><span
                                 className="label">Cosmetica</span></a>
                            </li>
                            <li ><a href="https://www.equilibra.it/contatti.html"><span className="label">Contatti</span></a></li>


                            {loggedIn ? <li><a href="/profilo">Profilo</a></li> : ''  }
                            {loggedIn ? '' : <li><a href="/login">LOGIN</a></li>}
                            {loggedIn ? '' : <li><a href="/registrazione">REGISTRATI</a></li>}
                            </ul>
                    </nav>
                    </div>
            </div>
            </div>
    </header>
}