import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {ErrorMap, FormValidate} from "../../helper";
import InputText from "../InputText";

export default function ChangePassword() {
    const navigate = useNavigate();

    const [form, setForm] = useState({
        password : {
            value: "",
            valid: true,
            touched: false,
            required: true
        },
        confirm_password : {
            value: "",
            valid: true,
            touched: false,
            required: true
        }
    });

    const [canSubmit, setCanSubmit] = useState(true);
    const [formError, setFormError] = useState('');
    const [sent, setSent] = useState(false);


    const onBlur = (e) => {
        const {name, value } = e.target;

        if (FormValidate(name, value, form)) {
            let newForm = form[name];
            newForm.valid = true;
            setForm(prevForm => {
                return {...prevForm,[name] : newForm}
            });
        }

    }

    const reset = () => {
        let newForm = form;
        Object.keys(newForm).forEach((item) => {
            newForm[item].valid = true;
            newForm[item].touched = false;
            newForm[item].value = '';
        });
        setForm(prevForm => {
            return {...prevForm,...newForm}
        });
    }

    const valid = () => {
        let invalid = [];
        let newForm = form;
        Object.keys(newForm).forEach((item) => {
            newForm[item].valid = FormValidate(item, newForm[item].value, newForm);
            newForm[item].touched = true;
        });

        invalid = Object.keys(newForm).filter(item => {
           return !newForm[item].valid
        });
        setForm(prevForm => {
            return {...prevForm,...newForm}
        });

        return invalid.length === 0
    }

    const change = (e) => {

        const {name, value } = e.target;

        let _new = {
            value: value
        }

        setForm(prevForm => {
            return {...prevForm,
                [name] : _new
            }
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!valid()) {
            return;
        }

        let registrationData = {
            "password": form.password.value,
            "password_confirm": form.confirm_password.value
        };

        window['UIdentity'].changePassword(registrationData, (data) => {

            if (data.success) {
                setSent(true);
                reset();
            }else{
                setCanSubmit(true);
                setFormError(ErrorMap(e.error.message));
            }

        });


    }


    return <div className="account">
        <div className="container">

            <form className="form password-form" onSubmit={onSubmit}>
                <h1 className="form--title">Modifica password</h1>

                <InputText label="Nuova password*" onblur={onBlur} onChange={change} type="password" name="password" data={form.password} />
                <InputText label="Conferma nuova password*" onblur={onBlur} onChange={change} type="password" name="confirm_password" data={form.confirm_password} />

                <ul className="password_pattern">
                    <li>Deve includere da 8 a 20 fra caratteri e simboli</li>
                    <li>Deve includere caratteri numerici e lettere</li>
                    <li>Deve includere almeno 1 lettera maiuscola e 1 minuscola</li>
                </ul>

                { sent ? <div className="form--success">stato salvato correttamente</div> : ''}

                {formError ? <div id="errors" className="form--error">{formError}</div> : ''}

                <div className="form-actions">
                    <button onClick={() => { navigate('/profilo') }} type="button" name="submit" className="button plain">
                        Annulla
                    </button>
                    <button id="submit" type="submit" name="submit" className="button" disabled={!canSubmit}>
                        Cambia
                    </button>
                </div>

            </form>

        </div>
    </div>;
}