export const Provinces = {
    "AG": "AGRIGENTO",
    "AL": "ALESSANDRIA",
    "AN": "ANCONA",
    "AO": "AOSTA",
    "AR": "AREZZO",
    "AP": "ASCOLI PICENO",
    "AT": "ASTI",
    "AV": "AVELLINO",
    "BA": "BARI",
    "BT": "BARLETTA - ANDRIA - TRANI",
    "BL": "BELLUNO",
    "BN": "BENEVENTO",
    "BG": "BERGAMO",
    "BI": "BIELLA",
    "BO": "BOLOGNA",
    "BZ": "BOLZANO",
    "BS": "BRESCIA",
    "BR": "BRINDISI",
    "CA": "CAGLIARI",
    "CL": "CALTANISSETTA",
    "CB": "CAMPOBASSO",
    "CI": "CARBONIA - IGLESIAS",
    "CE": "CASERTA",
    "CT": "CATANIA",
    "CZ": "CATANZARO",
    "CH": "CHIETI",
    "CO": "COMO",
    "CS": "COSENZA",
    "CR": "CREMONA",
    "KR": "CROTONE",
    "CN": "CUNEO",
    "EN": "ENNA",
    "FM": "FERMO",
    "FE": "FERRARA",
    "FI": "FIRENZE",
    "FG": "FOGGIA",
    "FC": "FORLì - CESENA",
    "FR": "FROSINONE",
    "GE": "GENOVA",
    "GO": "GORIZIA",
    "GR": "GROSSETO",
    "IM": "IMPERIA",
    "IS": "ISERNIA",
    "SP": "LA SPEZIA",
    "AQ": "L'AQUILA",
    "LT": "LATINA",
    "LE": "LECCE",
    "LC": "LECCO",
    "LI": "LIVORNO",
    "LO": "LODI",
    "LU": "LUCCA",
    "MC": "MACERATA",
    "MN": "MANTOVA",
    "MS": "MASSA - CARRARA",
    "MT": "MATERA",
    "VS": "MEDIO CAMPIDANO",
    "ME": "MESSINA",
    "MI": "MILANO",
    "MO": "MODENA",
    "MB": "MONZA E DELLA BRIANZA",
    "NA": "NAPOLI",
    "NO": "NOVARA",
    "NU": "NUORO",
    "OG": "OGLIASTRA",
    "OT": "OLBIA - TEMPIO",
    "OR": "ORISTANO",
    "PD": "PADOVA",
    "PA": "PALERMO",
    "PR": "PARMA",
    "PV": "PAVIA",
    "PG": "PERUGIA",
    "PU": "PESARO E URBINO",
    "PE": "PESCARA",
    "PC": "PIACENZA",
    "PI": "PISA",
    "PT": "PISTOIA",
    "PN": "PORDENONE",
    "PZ": "POTENZA",
    "PO": "PRATO",
    "RG": "RAGUSA",
    "RA": "RAVENNA",
    "RC": "REGGIO DI CALABRIA",
    "RE": "REGGIO NELL 'EMILIA",
    "RI": "RIETI",
    "RN": "RIMINI",
    "RM": "ROMA",
    "RO": "ROVIGO",
    "SA": "SALERNO",
    "SS": "SASSARI",
    "SV": "SAVONA",
    "SI": "SIENA",
    "SR": "SIRACUSA",
    "SO": "SONDRIO",
    "TA": "TARANTO",
    "TE": "TERAMO",
    "TR": "TERNI",
    "TO": "TORINO",
    "TP": "TRAPANI",
    "TN": "TRENTO",
    "TV": "TREVISO",
    "TS": "TRIESTE",
    "UD": "UDINE",
    "VA": "VARESE",
    "VE": "VENEZIA",
    "VB": "VERBANO - CUSIO - OSSOLA",
    "VC": "VERCELLI",
    "VR": "VERONA",
    "VV": "VIBO VALENTIA",
    "VI": "VICENZA",
    "VT": "VITERBO",
};

export const Gender = {
    '-1' : 'Seleziona',
    'M' : 'Maschio',
    'F' : 'Femmina',
    '' : 'Preferisco non rispondere',
}

export const UserFields = {
    first_name : {
        value: "",
        valid: true,
        touched: false,
        required: true
    },
    last_name : {
        value: "",
        valid: true,
        touched: false,
        required: true
    },
    gender : {
        value: "-1",
        valid: true,
        touched: false,
        required: true
    },
    dob : {
        value: "",
        valid: true,
        touched: false,
        required: true
    },
    zip : {
        value: "",
        valid: true,
        touched: false,
        required: false,
        error: ''
    },
    province : {
        value: "",
        valid: true,
        touched: false,
        required: false
    },
    email : {
        value: "",
        valid: true,
        touched: false,
        required: true,
        error: 'Inserire un indirizzo e-mail valido.',
    },
    password : {
        value: "",
        valid: true,
        touched: false,
        required: true
    },
    confirm_password : {
        value: "",
        valid: true,
        touched: false,
        required: true
    },
    optin_profiling : {
        value: false,
        valid: true,
        touched: false,
        required: false
    },
    optin_marketing : {
        value: false,
        valid: true,
        touched: false,
        required: false
    },
    optin_direct_marketing : {
        value: false,
        valid: true,
        touched: true,
        required: false
    },
    optin_tc : {
        value: true,
        valid: true,
        touched: true,
        required: false
    }
};

export function FormValidate(name, value, form) {
    if (!form[name].required) {
        return  true;
    }
    switch (name) {
        case 'password':
        case 'old_password':
            return /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=[A-Z0-9a-z\" !#$%&'()*+,-./:;<=>\]\[?@\\^_`{|}~]+$).{8,20}/.test(value)
        case 'email':
            return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value))
        case 'dob':
            return /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$/.test(value)
        case 'zip':
            return value.length=== 0 || (/^-?\d+$/.test(value) && value.length === 5)
        case 'gender':
            return value != "-1"
        case 'confirm_password':
            return value.length > 0 && value === form.password.value
        default:
            return value.length > 0
    }
}

export function AddUpdateQuery(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
        return uri.replace(re, '$1' + key + "=" + value + '$2');
    } else {
        return uri + separator + key + "=" + value;
    }
}

export function ErrorMap(error) {

    let text;
    switch (error) {
        case 'Email o password non valide.':
            text = 'Email o password non valide.';
            break;
        case 'detail':
            text = 'detail';
            break;
        default:
            text = 'Si è verificato un errore';
            break;
    }

    return text;
}

export function CheckValid(el, form) {

    const {name, value} = el.target;

    switch (name) {
        case 'password':
        case 'old_password':
            return (/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=[A-Z0-9a-z\" !#$%&'()*+,-./:;<=>\]\[?@\\^_`{|}~]+$).{8,20}/.test(value))
            break;
        case 'email':
            return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value))
            break;
        case 'dob':
            return (/^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/\-]\d{4}$/.test(value))
            break;
        case 'zip':
            return value.length === 0 || (/^-?\d+$/.test(value) && value.length === 5)
            break;
        case 'confirm_password':
            return value.length > 0 && value === form.password.value
        default:
            if (!form[name].required) {
                return true;
            } else {
                return value.length > 0
            }
    }

}

export function getDob(value) {
    let int_v = (value).split('/');
    return int_v[2] + '-' + int_v[1] + '-' + int_v[0]
}

function zeroPad(num) {
    var zero = 2 - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}
export function getDobR(dob) {
    let d = new Date(dob);
    return zeroPad(d.getDate()) + '/' + zeroPad(d.getMonth() + 1) + '/' + d.getFullYear()
}

export const dobSlash = (e) => {
    let textSoFar = e.target.value;
    if (e.keyCode !== 191) {
        if (e.keyCode !== 8) {
            if (textSoFar.length === 2 || textSoFar.length === 5) {
                e.target.value = textSoFar + '/';
            } else if (e.keyCode === 86 && textSoFar.length === 8) {
                e.target.value =
                    textSoFar.substr(0, 2) +
                    '/' +
                    textSoFar.substr(2, 2) +
                    '/' +
                    textSoFar.substr(4, 4)
                ;
            }
        } else {
            //backspace would skip the slashes and just remove the numbers
            if (textSoFar.length === 5) {
                e.target.value = textSoFar.substring(0, 4);
            } else if (textSoFar.length === 2) {
                e.target.value = textSoFar.substring(0, 1);
            }
        }
    } else {
        e.target.value = textSoFar.substring(0, textSoFar.length - 1);
    }

}